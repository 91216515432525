
.user_Import_block{
    padding: 1rem;
    background-color: $white_1;
    box-shadow: 1px 1px 3px $smokeWhite;
    border: 1px solid $smokeWhite_3;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    position: relative;
    h4{
        margin-bottom: 2rem;
        padding-right: 1rem;
    }
    .or_txt{
        
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        &::before{
            content: "";
            @include size(1px,100%);
            display: block;
            background-color: $smokeWhite_3 ;
            @include position(absolute, $top: 0, $right: null, $bottom: null, $left: 50%);
        }
        span{
            background-color: $white_1;
            position: relative;
            z-index: 1;
            font-weight: 600;
        }
    }
    .file_btn_div{
        padding-left: 0px;
        .custom-file-upload {
            @extend .buttons_orange;
            cursor: pointer;
            width: 100%;
            text-align: center;
            padding: 0.55rem;
            margin-bottom: 0;
           }
    }
    .upload_csv_div{
        
        .csv_select{
            height: auto !important;
            padding: 0.8rem 0;
        }
    } 
    input[type="file"]{
        display: none;
    }

}
.spacer{
    height: 50px;
}

@media(max-width:$break-lg){//1024px
    .user_Import_block .file_btn_div .custom-file-upload{
        margin-left: 0;
    }
}
@media(max-width:$break-sm){//767
    .user_Import_block .file_btn_div {
        padding-left: 15px;
    }
    .user_Import_block{
        .or_txt{
            margin: 1rem 0;
            &::before{
                @include size(100%, 1px);
                top: 50%;
                left: 0;
            }
        }
        .file_btn_div .custom-file-upload{
            margin-top: 0.5rem;
        }
    }
 .upload_csv_div{
     margin-top: 1rem;
 }
}