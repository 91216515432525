.newFormtabs {
  float: left;
  width: 100%;
  @include size(100%, $height: null);

  .topTbsPane {
    border: none;
    margin-top: 0.5rem;

    & > li {
      @include size(12.5%, $height: null);
      text-align: center;

      &:last-child a::before {
        content: none;
      }
    }

    .nav-link {
      background-color: $tab_BgColor;
      cursor: pointer;
      padding-bottom: 15px;
      padding-bottom: 1rem;

      &:hover {
        border-top-color: transparent !important;
        border-bottom-color: transparent !important;
        background-color: $tab_BgColor_active;
        color: $tab_txtColor;
        border-right: 1px solid $tab_txtColor;
      }

      &::before {
        content: "";
        border-right: 1px solid $smokeWhite_3;
        height: 80px;
        @include position(
          absolute,
          $top: 15px,
          $right: 0,
          $bottom: null,
          $left: null
        );
      }
      border: none;
      border-radius: 0px;
      position: relative;
      height: 100%;
      &.active {
        &::after {
          content: url(../images/down_arrow.png);
          @include position(
            absolute,
            $top: null,
            $right: null,
            $bottom: -3px,
            $left: 0
          );
          z-index: 1;
          display: block;
          width: 100%;
          transform: rotate(180deg);
        }
        &::before {
          content: none;
        }
      }
    }
    .profilelabel {
      font-size: 0.9rem;
      margin-top: 0.3rem;
      line-height: 18px;
      letter-spacing: 0.4px;
    }
  }
  .formDiv {
    background-color: $smokeWhite_4;
  }
  .nav-link {
    color: $smokeGray_4;
    &.active {
      font-weight: 500;
    }
  }
  .icon-tick-consultant {
    @include size(25px, 25px);
    @include font-size-color(1.8rem, $white_1);
    display: block;
    margin: auto;
    border-radius: 50%;
    line-height: 1;
    text-align: center;
  }
  .tab_form_content {
    padding: 1rem;
    background-color: $white_1;
    box-shadow: 1px 1px 3px $smokeWhite;
    border: 1px solid $smokeWhite_3;
    margin-top: 0.2rem;
    position: relative;
    width: 100%;
    .tab_heading {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
    }
  }
  .tab_subHead {
    h3 {
      font-size: 1rem;
      margin-bottom: 0;
    }
  }
}
.newFormtabs .topTbsPane .nav-item.show .nav-link,
.newFormtabs .topTbsPane .nav-link.active {
  color: $tab_txtColor;
  background-color: $tab_BgColor_active;
  border-color: $tab_BorderColor;
  box-shadow: none;
  border-right: 1px solid $tab_BorderColor;
}
.fade {
  &.in {
    opacity: 1;
  }
}
.form-group input,
.form-group textarea,
.form-group input:focus {
  @extend .formInput;
}
.form-group {
  .error-message {
    color: $error_red;
    font-size: 12px;
    font-style: italic;
  }
  .required-asterisk {
    color: $error_red;
    font-size: 10px;
    margin-top: 3px;
    margin-right: 2px;
  }
}
.form-group input.error,
.form-group textarea.error {
  border: 1px solid $error_red;
}
.form-group select {
  @extend .select_box;
}
.tab_form_content h4 {
  margin-bottom: 2rem;
  padding-right: 1rem;
}
/* ----Inner Tabs Pane------- */
.inner_tabsWrapper {
  margin-top: 2rem;
  .nav-link {
    @include font-size-color(1.2rem, $n_tabTxt);
    font-weight: 300;
  }
  .nav-tabs {
    position: relative;
    padding-bottom: 1px;
    .nav-item {
      width: auto;
      margin-right: 0rem;
      position: relative;
      left: 0.8rem;
      cursor: pointer;
      border-bottom: 3px solid $tab_BgColor_active !important;
    }
    .nav-link {
      padding: 0.6rem 1.5rem 0.5rem;
      position: relative;
      font-size: 0.9rem;
      border-top-left-radius: 2rem;
      border-top-right-radius: 2rem;
      background-color: $tab_NBgColor;
      box-shadow: 1px 0 5px rgba(0, 0, 0, 0.164);
      cursor: pointer;
      line-height: 18px;
      letter-spacing: 0.4px;
      &::before {
        display: block;
        content: " ";
        position: absolute;
        top: 0;
        height: 101%;
        width: 15px;
        background-color: #fff;
        transition: all 250ms ease;
        border-radius: 0 8px 0 0;
        right: 0px;
        transform: skew(30deg, 0deg);
        box-shadow: rgba(0, 0, 0, 0.164) 5px 2px 5px,
          inset rgba(255, 255, 255, 0.164) -1px 0;
      }
      &:after {
        display: block;
        content: " ";
        position: absolute;
        top: 0;
        height: 101%;
        width: 15px;
        background-color: #fff;
        transition: all 250ms ease;
        border-radius: 8px 0 0 0;
        left: 0px;
        transform: skew(-30deg, 0deg);
        box-shadow: rgba(0, 0, 0, 0.164) -3px 2px 5px,
          inset rgba(255, 255, 255, 0.164) 1px 0;
      }
      &.active,
      &:hover {
        background-color: $tab_NBgColor_active;
        color: $tab_txtColor;
        position: relative;
        z-index: 1;
        &::after,
        &::before {
          background-color: $tab_NBgColor_active;
        }
      }
      &.disable {
        opacity: 0.6;
        cursor: not-allowed !important;
      }
    }
  }
  .tab-content {
    margin-top: 2rem;
  }
  /* ------Third Level tabs--------- */
  .thirdLevelTabs {
    .nav-tabs {
      flex-wrap: nowrap;
      .nav-item {
        left: 0;
      }
      .nav-link {
        border-top-left-radius: 0.5rem;
        &:after {
          content: none;
        }
      }
      li {
        flex: none;
      }
    }
  }
  /* ------End Third Level tabs--------- */
}
.inner_tabsWrapper .nav-tabs,
.inner_tabsWrapper .nav-tabs .nav-link {
  border: none;
}
.form-group {
  .lbl {
    margin-bottom: 0.3rem;
    margin-top: 0.8rem;
    label {
      margin: 0;
    }
  }
  input[type="checkbox"] {
    width: auto;
  }
}
.toggleWrapper {
  display: flex;
  align-items: flex-start;
  position: relative;
  top: 3px;
  .switchBox {
    margin: 0 0.5rem;
  }
  span {
    color: $toggle_txt_color;
  }
}
.password_box {
  position: relative;
  .toggle-password {
    @include position(
      absolute,
      $top: null,
      $right: 0.5rem,
      $bottom: 1rem,
      $left: null
    );
  }
}
.form-check-inline {
  .form-check-label {
    margin-left: 0.5rem;
    margin-right: 1.5rem;
  }
}
.tbl_midHead {
  h3 {
    font-size: 1rem;
  }
}
table.table {
  margin-top: 0.5rem;
  th {
    @extend .tableHead;
    border-bottom: 0px;
  }
  tr:nth-child(2n) {
    background-color: $table_odd_tr_bg;
  }
  td {
    color: $table_txt_color;
    border: none;
  }
}
.lbl {
  position: relative;
  a {
    @include position(absolute, $top: 0, $right: 0, $bottom: null, $left: null);
    color: $link_color;
  }
}
.flexbox {
  display: flex;
  justify-content: space-between;
}
.gryBoxInfo_box {
  padding: 10px;
  background: $white_3;
  margin-bottom: 0px;
  h5 {
    font-size: 1rem;
  }
}
h1.disclosures_box_h1 {
  border-bottom: solid 2px $smokeWhite;
  font-size: 1rem;
  padding-bottom: 5px;
  margin-bottom: 1rem;
}
.disclosures_box_questions {
  margin-bottom: 10px;
  padding: 0 15px;
}
table.dataTable.table-bordered,
.table thead th,
.table-bordered td {
  border-color: transparent !important;
}
#locationInfo .nav-tabs .nav-link {
  padding-bottom: 0.2rem;
  padding-top: 1rem;
}
.icon-empty:before {
  color: $tab_disabled;
}
.icon-ongoing:before {
  color: $tab_ongoing;
}
.icon-processdone:before {
  color: $tab_success;
}
.icon-processpending:before {
  color: $tab_pending;
}
/* ----Scroll bar css------- */
.scrollbar-2 {
  position: relative;
  scrollbar-width: thin !important;
  scrollbar-color: #b7b7b7 #eee !important;
}
.scrollbar-2:after {
  content: "";
  height: 2px;
  width: 100%;
  background: $tab_BgColor_active;
  position: absolute;
  left: 0;
  bottom: 1px;
}
.scrollbar-2::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  position: absolute;
}
.scrollbar-2::-webkit-scrollbar-track {
  background-color: #eee;
}
.scrollbar-2::-webkit-scrollbar-thumb {
  background-color: #b7b7b7;
}
.scrollbar-2::-webkit-scrollbar-track,
.scrollbar-2::-webkit-scrollbar-thumb {
  border-radius: 12px;
}
[class^="scrollbar"] {
  overflow-x: hidden;
}
[class^="scrollbar"] .sub_scrollbar {
  width: 100%;
  padding: 0;
}
.thirdLevelTabs .scrollbar-2:hover {
  overflow-x: auto;
}
