#stepperComponentMain {
  @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");
  body {
    font-family: "Montserrat", sans-serif;
    margin: 0px;
  }
  /*start bms steps style*/
  .stepperWrapper {
    font-family: "Montserrat", sans-serif;
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-left: 0px;
    overflow-x: auto;
    overflow-y: hidden;
    min-height: 110px;
  }
  .stepperItem {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 0 auto;
    width: 10%;
  }

  .stepperItem::before {
    position: absolute;
    content: "";
    border-bottom: 12px solid #e1e1e1;
    width: 100%;
    top: 50%;
    left: -50%;
    z-index: 2;
    transition: all 0.5s ease;
  }

  .stepperItem::after {
    position: absolute;
    content: "";
    border-bottom: 12px solid #e1e1e1;
    width: 100%;
    top: 50%;
    left: 50%;
    z-index: 2;
    transition: all 0.5s ease;
  }

  .stepperItem .stepCounter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: #e1e1e1;
    margin-bottom: 6px;
    border: 1px solid #a6a6a6;
  }

  .infoIcon {
    min-width: 20px;
  }

  .stepName {
    display: flex;
  }

  .stepperItem.active,
  .stepperItem.completed .stepName,
  .stepperItem.completed .stepBottomTxt {
    font-weight: 600;
  }
  .stepperItem.active .stepName {
    color: #000;
  }
  .stepperItem.completed .stepName,
  .stepperItem.completed .stepBottomTxt {
    color: #3dae18;
  }
  .stepperItem.completed .stepCounter {
    background-color: #4bb543;
  }
  .stepperItem.completed .stepCounter::before {
    content: none;
  }

  .stepperItem.completed::after {
    position: absolute;
    content: "";
    border-bottom: 12px solid #4bb543;
    width: 100%;
    top: 50%;
    left: 50%;
    z-index: 3;
    transition: all 0.5s ease;
  }

  .stepperItem:first-child::before {
    content: none;
  }
  .stepperItem:last-child::after {
    content: none;
  }
  .stepName,
  .stepBottomTxt {
    text-align: center;
    line-height: normal;
    font-size: 14px;
    color: #a8a8a8;
    margin-bottom: 10px;
    width: min-content;
    min-height: 16px;
  }
  .stepCounter::before {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    background: #8d8d8f;
    border-radius: 100px;
  }
  .stepperItem.completed .stepCounter::after {
    content: "";
    display: block;
    width: 8px;
    height: 20px;
    border: solid #ffffff;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    margin-top: -2px;
  }
  @media (max-width: 1440px) {
    .stepName,
    .stepBottomTxt {
      text-align: center;
      line-height: normal;
      font-size: 12px;
      color: #a8a8a8;
      margin-bottom: 10px;
    }
  }
  @media (max-width: 767px) {
    .stepperItem {
      width: 30%;
    }
  }
  @media (max-width: 1023px) {
    .stepperItem {
      width: 18%;
    }
  }
}
