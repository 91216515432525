@import "src/assets/sass/_variables.scss";

.toastMessageMain {
  border-radius: 4px;
  box-shadow: 0px 3px 5px -1px $toast_shadowOne,
    0px 6px 10px 0px $toast_shadowTwo, 0px 1px 18px 0px $toast_shadowThree;
  font-weight: 500;
  font-size: 13px;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  padding: 6px 16px;
  width: fit-content;
  display: grid;
  grid-template-columns: 25px auto 25px;
  column-gap: 10px;
  align-items: center;
  background-color: $toast_info;
  color: $white_1;
  position: fixed;
  top: 20px;
  right: 20px;
  max-width: 700px;
  z-index: 99999; //highest z-index, so toast message can show on the top of everything

  &.danger {
    background-color: $toast_danger;
  }

  &.warning {
    background-color: $toast_warning;
  }

  &.success {
    background-color: $toast_success;
  }

  .closeControl {
    background-color: transparent;
    outline: none;
    border: none;
    width: 28px;
    height: 28px;

    .closeIcon {
      path {
        fill: $white_1;
      }
    }
  }

  .toastMessageIcon {
    width: 25px;

    path {
      fill: $white_1;
    }
  }

  .dangerIcon {
    width: 18px;

    circle,
    line {
      stroke: $white_1;
    }
  }

  .successIcon {
    width: 20px;
    height: 20px;

    g {
      width: 13px;
      fill: $white_1;
    }
  }

  .warningIcon {
    line,
    path {
      stroke: $white_1;
    }

    circle {
      fill: $white_1;
    }
  }
}
