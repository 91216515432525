@media (max-width:$break-lg) {
    html {
        font-size: 81.25% !important;
    }

    //1024px
    .w140 {
        width: 80px !important;
    }

    .tbl_Am_Pm .lbl {
        font-size: 0.8rem;
    }

    .newFormtabs {
        .topTbsPane {
            flex-wrap: nowrap;
            overflow-x: auto;
            overflow-y: hidden;

            &>li {
                width: 15.5%;
                text-align: center;
                flex: none;
            }
        }
    }


}

@media (max-width:$break-md) {
    //991px

    html {
        font-size: 75% !important;
    }

    .navbar-custom-menu {
        &>.navbar-nav {
            &>li {
                margin: 0 1rem 0 0;
            }
        }
    }

    .newFormtabs .topTbsPane .nav-link.active:after {
        left: 40%;
        bottom: -1.6rem;
    }


}

@media (max-width:$break-sm) {
    //767px

    .inner_tabsWrapper {

        // .nav-tabs {
        //     display: none;
        // }
        .nav-tabs {
            padding-top: 4px;
        }




        .thirdLevelTabs {
            ul {
                display: flex;
            }
        }

    }

    .tab-content {
        .tab-pane {
            display: block;
        }
    }

    .nav-tabs {
        display: none;
    }

    .panel-title a {
        display: block;
    }

    .panel {
        margin: 0;
        box-shadow: none;
        border-radius: 0;
        margin-top: -2px;
    }

    .tab-pane {

        .panel {
            border-radius: 5px;
        }
    }


    .flexbox {
        flex-wrap: wrap;
    }

    .buttons_gray,
    a.btngry,
    input.btngry,
    .btngry {
        margin: 0px;
        padding: 4px !important;
    }

    #InfobarBtn {
        @include position(absolute, $top: 8px, $right: 0.1rem, $bottom: null, $left: null)
    }

    ul.nav.nav-tabs {
        flex-wrap: inherit;
        overflow-x: auto;
        overflow-y: hidden;

        li {
            flex: none;
        }
    }

    .newFormtabs .topTbsPane .nav-link.active:after {
        display: none;
    }

    .newFormtabs {
        .topTbsPane {
            flex-wrap: nowrap;
            overflow-x: auto;
            overflow-y: hidden;

            >li {
                width: 40%;
                flex: none;

                .nav-link {
                    padding: 0.5rem;
                }
            }
        }

        .tab_subHead h3 {
            margin-bottom: 1rem;
        }
    }

    table {
        .toggleWrapper {
            align-items: center;

            label {
                left: inherit;
            }
        }

        .data_icons,
        .data_icons:hover {
            margin-right: 0.1rem;
        }
    }

    .sideModal .modal-dialog {
        margin-bottom: 0;
        margin: 1rem 0 auto;
        height: calc(100vh - 20px);
    }

    .sideModal .modal-dialog .modal-content {
        border-top-left-radius: 0rem;
    }

    .adddeaCds .modal-dialog {
        max-width: initial;
    }

    .main-header.navbar {
        float: left;
        width: 100%;
        display: block;

        ul.navbar-nav {
            display: inline-block;
        }

        .top_header {
            float: left;
            width: 88%;
            margin-top: 0rem;
            margin-left: 1rem;
        }

        .navbar-custom-menu .navbar-nav {
            width: 100%;
        }

        .ticket a {
            padding: 10px;
        }

        .navbar-custom-menu>.navbar-nav>li {
            margin: 0 1rem 0.5rem 0;
            float: left;
            width: 42%;
            margin-left: 1rem;
        }
    }

    .btns_grp button {
        margin-top: 0.6rem;
    }

    .inner_tabsWrapper .nav-tabs .nav-item {
        margin-right: 1rem;
    }
}