@import "src/assets/sass/_variables.scss";

.spinnerMain {
  .spinnerFullScreenContainer {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    display: grid;
    place-content: center;
    margin: 0;
    background-color: $white_1;
  }

  .spinnerContainer {
    display: grid;
    place-content: center;
    margin: 50px;
  }

  .spinner {
    width: 58px;
    height: 58px;
    border: 3px solid $header_color;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    padding: 10px;
    animation: rotation 1s linear infinite;

    &::after {
      content: "";
      box-sizing: border-box;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 3px solid;
      border-color: $btn_orange_hover transparent;
    }
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
