
@mixin size($width, $height) {
  width: $width;
  height: $height;
}

@mixin font-size-color($size,$color){
  font-size: $size;
  color: $color;
}

@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}
//  @include position(absolute, $top: 1em, $left: 50%);

// When the Input is 1 Variable
@mixin border-radius($pixel) {
  border-radius: $pixel;
}

// When the Inputs are 4 Variables
@mixin border-radius-s($tl, $tr, $br, $bl) {
  border-top-left-radius: $tl;
  border-top-right-radius: $tr;
  border-bottom-right-radius: $br;
  border-bottom-left-radius: $bl;
}

@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}




//
// Mixins: Scrollbar
//

@mixin scrollbar-color-gray() {
    scrollbar-color: #a9a9a9 transparent;
  
    &::-webkit-scrollbar-thumb {
      background-color: #a9a9a9;
    }
  
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  
    &::-webkit-scrollbar-corner {
      background-color: transparent;
    }
  }
  
  @mixin scrollbar-width-thin() {
    scrollbar-width: thin;
  
    &::-webkit-scrollbar {
      width: .5rem;
      height: .5rem;
    }
  }
  
  @mixin scrollbar-width-none() {
    scrollbar-width: none;
  
    &::-webkit-scrollbar {
      width: 0;
    }
  }
  
  //
  