.block_box{
    float: left;
    width: 100%;
    clear: both;
}

.buttons_orange{
    border-radius: 4px;
    color: $btn_orange_txt;
    font-size: 1.1rem;
    font-weight: 500;
    background-color: $orng_btn_bg ;
    border: solid 1px $btn_orrange;
    padding: 5px;

    &:disabled, &[disabled]{
        cursor: not-allowed !important;
        pointer-events: all !important;
    }
}
.buttons_orange_hover{
    background: $btn_orange_hover;
    color: $btn_orange_txt;
}
.buttons_gray{
    border: 1px solid $btn_gray_border !important;
    border-radius: 5px !important;
    background-color: $gray_btn_bg !important;
    font-size: 15px !important;
    color:$btn_gray_txt !important;
    font-weight: 500 !important;
    padding: 5px !important;
    margin: 0 5px !important;
}
.buttons_gray_hover{
    background: $btn_gray_hover;
    color: $btn_gray_txt   
}

.tableHead{
    background-color: $table_thead !important;
    color: $table_thead_txt !important;
}

.select_box{
    width: 100%;
	padding: 9px 5px;
	border: solid 1px $inpt_select;
	color: $smokeGray_10;
    border-radius: 5px;
    height: auto !important;
}
.formInput{
    width: 100%;
	padding: 8px 5px;
	border: solid 1px $inpt_select;
	color: $smokeGray_10;
	border-radius: 5px;
}
.sideBarNavIcon{
    width: 28px;
    height: 28px;
    position: absolute;
    left: 20px;
    font-size: 1.8rem;
}