#privilegeViewerMain {
  .gridTitle {
    color: #ff8636;
    font-size: 24px;
    margin-bottom: 25px;
    font-weight: 600;
  }

  .card {
    background-color: #fff;
    box-shadow: 1px 4px 6px 0px #bab8b870;
    padding: 15px 20px 5px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.175);
    margin-bottom: 25px;
  }

  .privilegeTableHeading {
    font-weight: bold;
    font-size: 20px;
    margin-top: 20px;
  }

  .headingValue {
    font-size: 17px;
    font-weight: normal;
  }
}
