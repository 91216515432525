  
  .tpModal{
      .modal-dialog{
        max-width: 800px;
      }
    .table .thead-dark th {
        background-color: $smokeGray_4;
        padding: 0.5rem;
  } 
  .modal_StateLicenseTbl{
      margin-top: 1rem;
  }
}
.msgAddDesc {
    padding: 0;
    margin: 0 0 5px;
    button.btn {
        background: none;
        border: none;
        color:$l_blue;
        padding: 0;
        vertical-align: top;
    }
}

  /*----Right Side Bar Css----*/
  .sideModal{
    .modal-header{
      align-items: center;
    }
    top: unset !important;
    left: unset !important;
    padding-right: 0 !important;
    height: 100vh;
    .modal-dialog{
      margin-bottom: 0;
      margin: 0rem 0 auto;
      height: calc(100vh - 0px);
      .modal-content{
        height: 100%;
        @include border-radius-s(1rem, 0rem, 0rem, 0rem);
        overflow-x: hidden;
        overflow-y: auto;
        .modal_StateLicenseTbl{
          margin-top: 1rem;
        }
      }

    }
    .modal-footer{
      border: none;
    }
    .modal-title{
      font-size: 1.8rem;
    }
    .modal_StateLicenseTbl {
      margin-bottom: 0px;
    }
  }
  .mdl-footer>:not(:last-child) {
    margin-right: 0.25rem;
}
.mdl-footer>:not(:first-child) {
  margin-left: 0.25rem;
}
.mdl-footer{
  text-align: right;
}
  .cred_data .modal-dialog{
    max-width: initial
  }
  .adddeaCds .modal-dialog{
    max-width: 300px;
  }

