.main-header {
  position: relative;
  z-index: 99;
  background: $header_border;
  padding: 8px 0 5px 0;
  box-shadow: 1px 1px 3px $smokeWhite;
}

.top_header {
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;

  h1 {
    font-size: 1.8rem;
    margin: 0;
    color: $notification_txt;
    display: flex;
    justify-self: center;
  }
}

.hamburger_icon {
  color: $header_txt !important;
  font-size: 2rem !important;
}

.navbar-custom-menu {
  width: 396px;
  & > .navbar-nav {
    & > li {
      position: relative;
      color: $notification_txt;
      margin: 0 2.8rem 0 0;
      display: block;

      a {
        color: $notification_txt;
        background: none !important;
        text-decoration: none;
      }

      img {
        display: block;
        margin: 7px 8px 5px 0;
      }

      span {
        display: block;
      }

      .task-notifi {
        a {
          &.count {
            display: block;
            background: $notification_Bg !important;
            @include border-radius(100%);
            @include size(23px, 22px);
            @include font-size-color(12px, $notification_txt);
            @include position(
              absolute,
              $top: 0px,
              $right: null,
              $bottom: null,
              $left: null
            );
            text-align: center;
            font-weight: bold;
            line-height: 22px;
          }
        }
      }
    }
  }

  .navbar-nav {
    align-items: center;
  }
}

.ticket {
  font-size: 1.1rem;
  position: relative;
  background: $header_reqst_btn;
  float: left;
  padding: 4px 0px;
  margin: 4px 0;
  border: 2px dashed $header_border;

  &:before {
    top: -5px;
    right: -5px;
    border-left: $header_border 5px dotted;
    border-bottom: $header_border 5px dotted;
    border-radius: 0 0 0 20px;
  }

  &:after {
    top: -5px;
    left: -5px;
    border-right: $header_border 5px dotted;
    border-bottom: $header_border 5px dotted;
    border-radius: 0 0 20px 0;
  }

  a {
    padding: 20px;
    text-decoration: none;
    color: $header_txt;

    &:before {
      bottom: -5px;
      right: -5px;
      border-left: $header_border 5px dotted;
      border-top: $header_border 5px dotted;
      border-radius: 20px 0 0 0;
    }

    &:after {
      bottom: -5px;
      left: -5px;
      border-right: $header_border 5px dotted;
      border-top: $header_border 5px dotted;
      border-radius: 0 20px 0 0;
    }
  }
}

.ticket:after,
.ticket:before,
.ticket a:after,
.ticket a:before {
  content: "";
  position: absolute;
  z-index: 1;
  @include size(10px, 10px);
  background: linear-gradient($header_border, $header_border) padding-box,
    $header_reqst_btn;
}

.task-notifi {
  position: relative;
}

.user-menu {
  .navbar-custom-menu {
    width: 396px;
    & > .navbar-nav {
      & > li {
        span {
          display: block;
        }
      }
    }
  }

  .caret {
    display: inline-block !important;
  }

  .dropdown-menu {
    display: block;
    top: 100%;
    left: -140px;
    z-index: 1050;
    padding: 5px;
    min-width: 200px;
    box-shadow: none;
    border: none;
    border-radius: 0;
    background-color: lightgray;

    .user-name {
      background: $header_color;
      font-weight: bold;
      color: $white_1 !important;

      &:hover {
        background: $header_color;
      }
    }

    & > li {
      background: $header_drp_down_bg;
      padding: 5px;

      &:hover {
        background: $header_list_hover;
      }
    }
  }
}

.user-menu .dropdown-menu > li > a,
.user-menu .dropdown-menu > li {
  @include font-size-color(0.9rem, $header_link_txt !important);
  text-decoration: none;
}

.content-header {
  float: left;
  width: 100%;
  background-color: $bredcum_bg;
  margin-bottom: 10px;
  position: relative;

  .breadcrumb {
    background-color: transparent;
    padding: 0rem 0.2rem;
    margin-bottom: 0px;
  }
}

.user-menu .dropdown-menu > li {
  &:hover {
    background-color: $header_list_hover;
  }

  > a {
    display: block;
    padding: 3px 0px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    white-space: nowrap;
  }

  i {
    margin-right: 0.5rem;
  }
}

.infomation {
  float: right;

  .icon-info {
    font-size: 3rem;
  }
}

/* -----Info Bar -------- */
.infoBar {
  float: left;
  width: 100%;
  padding-left: 1rem;
  margin-bottom: 0.6rem;

  .tip-icon {
    background: $infoBox_icon;
    @include size(40px, 40px);
    @include position(
      absolute,
      $top: 0,
      $right: null,
      $bottom: null,
      $left: -9px
    );
  }

  .tip-box {
    color: $infoBox_bg_txt;
    background: $infoBox_bg;
    padding: 8px 8px 3px 30px;
    margin: 0 0 0 10px;

    ul {
      margin-left: 0;
      padding-left: 1rem;
    }

    p {
      padding-bottom: 5px;
      margin: 0;
      @include font-size-color(1.3rem, $infoBox_bg_txt);
    }
  }

  .headBox {
    position: relative;
  }
}

.InfobarBtn {
  background-color: transparent;
  outline: none;
  border: none;

  &:hover,
  &:active,
  &:focus {
    border: none !important;
    outline: none !important;
  }
}

.customebreadcum .row {
  align-items: center;
}

@media (max-width: 767px) {
  .m-container {
    display: block !important;
  }
}
