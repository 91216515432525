@import "src/assets/sass/_variables.scss";

@mixin breadcrumb {
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  column-gap: 12px;
  align-items: center;
}

.breadcrumbsMain {
  @include breadcrumb;
  padding: 5px 2px;

  .chevronIcon {
    path {
      stroke: $smokeGray_2;
    }
  }

  .breadCrumbItem {
    @include breadcrumb;
  }

  .breadcrumbLink {
    font-size: 15px;
    text-decoration: none;
    color: $smokeGray_2;

    &.inactive {
      color: $smokeGray_5;
      cursor: pointer;
    }

    &:hover {
      color: $smokeGray_5;
    }
  }
}
