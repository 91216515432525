#dashboardWrapper {
  .sectionSpace {
    padding: 70px 0px;
  }
  .extraSpace {
    margin-right: 150px;
  }
  .extraSpaceLeft {
    margin-left: 150px;
  }
  .innerSec article {
    color: #000;
  }
  .imageUpaind {
    z-index: 0;
    position: relative;
  }
  .imagePosition {
    position: absolute;
    width: 64%;
    bottom: -70px;
    right: -120px;
  }
  .imagePositionLeft {
    position: absolute;
    width: 64%;
    bottom: -80px;
    left: -120px;
  }

  .orangeShape1,
  .orangeShape3 {
    position: absolute;
    top: -70px;
    right: 0px;
    z-index: -1;
  }
  .orangeShape3 {
    right: inherit;
    left: 0;
  }
  .orangeShape2,
  .orangeShape4 {
    position: absolute;
    bottom: -70px;
    left: 196px;
    z-index: -1;
  }
  .orangeShape4 {
    right: 196px;
    left: inherit;
  }
  .heading {
    color: #ff8636;
    font-size: 35px;
    margin-bottom: 25px;
    font-weight: 400;
  }
  .heading span {
    font-weight: bold;
  }
  .bannerText article p.paragraph {
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 30px;
    max-width: 580px;
    color: #000;
  }
  .bgLightBlue {
    background-color: #f7f9ff;
  }

  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .orangeShape1,
    .orangeShape3 {
      position: absolute;
      top: -70px;
      right: -70px;
      z-index: -1;
    }
    .orangeShape3 {
      right: inherit;
      left: -70px;
    }
    .orangeShape2,
    .orangeShape4 {
      left: 165px;
    }
    .orangeShape4 {
      right: 165px;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199.5px) {
    .orangeShape1,
    .orangeShape3 {
      position: absolute;
      top: -70px;
      right: -70px;
      z-index: -1;
    }
    .orangeShape3 {
      right: inherit;
      left: -70px;
    }
    .orangeShape2,
    .orangeShape4 {
      left: 130px;
    }
    .orangeShape4 {
      right: 130px;
    }
  }
  @media only screen and (max-width: 991.5px) {
    .bannerText article h1 {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 15px;
    }
    .bannerText article p {
      font-size: 16px;
      margin-bottom: 15px;
      max-width: 100%;
    }
    .bannerText article ul li {
      font-size: 16px;
      font-weight: normal;
      margin-bottom: 5px;
    }
    .orangeShape1,
    .orangeShape3,
    .orangeShape2,
    .orangeShape4 {
      display: none;
    }
    .extraSpace,
    .extraSpaceLeft {
      margin: 0;
    }
    .imagePosition {
      width: inherit;
      bottom: -30px;
      right: -2px;
    }
    .imagePositionLeft {
      position: absolute;
      width: inherit;
      bottom: -30px;
      left: -2px;
    }
    .bannerText article p.paragraph {
      max-width: 100%;
    }
    .sectionSpace {
      padding: 60px 0px;
    }

    .heading {
      font-size: 24px;
      margin-bottom: 15px;
      font-weight: 400;
      margin-top: 45px;
    }
    .bannerText article p.paragraph {
      font-size: 14px;
      margin-bottom: 20px;
      color: #000;
    }
  }
  @media only screen and (max-width: 767px) {
    .heading,
    .bannerText article h1 {
      font-size: 20px;
      margin-top: 40px;
    }
  }
  @media only screen and (max-width: 470px) {
    .imagePositionLeft,
    .imagePosition {
      max-width: 50%;
    }
  }
}
