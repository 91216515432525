@import "src/assets/sass/_variables.scss";

.singleSelectMain {
  width: 100%;
  position: relative;
  border: solid 1px $smokeWhite;
  border-radius: 5px;
  padding: 8px 5px;
  display: flex;
  justify-content: space-between;
  background-color: $white_1;
  cursor: pointer;

  &:hover {
    .clearFieldControl {
      display: block;
    }
  }

  .clearFieldControl {
    display: none;
    width: 20px;
    height: 20px;
    border: none;
    outline: none;
    background-color: inherit;
    font-size: 15px;
    margin-right: 10px;
    color: $smokeGray_10;
  }

  &.disabled {
    opacity: 0.6;
    background-color: $white_4;
  }

  &.error {
    border: 1px solid $error_red;
  }

  .singleSelectInput {
    width: 100%;
    background-color: inherit;
    outline: none;
    color: var(--text);
    font-size: 14px;
    color: $smokeGray_10;
    padding: 0;
    border: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:focus {
      border: none;
      padding: 0;
    }
  }

  .carotIcon {
    color: $smokeGray_10;
    margin: 3px 10px 0 0;
  }

  .dataListMenu {
    position: absolute;
    z-index: 9;
    top: 45px;
    left: 0px;
    background-color: $white_1;
    width: 100%;
    box-shadow: 2px 1px 14px -2px $shadowTwo;
    max-height: 150px;
    overflow-y: auto;
    display: grid;
    grid-template-rows: min-content;
    border-radius: 5px;
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */

    &.spinnerContainer {
      display: grid;
      place-content: center;
      height: 100px;
    }

    &::-webkit-scrollbar {
      display: none;
      /* Safari and Chrome */
    }
  }

  .dataItem {
    padding: 5px 10px;
    cursor: pointer;
    font-size: 15px;

    &:hover,
    &.active {
      background-color: $smokeWhite_2;
    }

    &.selected {
      background-color: $smokeGray_13;
    }
  }

  .noResultFound {
    padding: 10px 20px;
    text-align: center;
  }

  .noResultFoundText {
    font-size: 16px;
  }
}
