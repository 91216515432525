@import "src/assets/sass/_variables.scss";

.secureInputFieldMain {
  width: 100%;
  border: solid 1px $inpt_select;
  color: $smokeGray_10;
  border-radius: 5px;
  padding: 7px 5px;
  display: grid;
  grid-template-columns: auto 40px;

  &.error {
    border: 1px solid $error_red;
  }

  .inputControl,
  .inputControl:focus {
    padding: 0;
    border: none;
    outline: none;
    border-radius: 0;
  }

  .showHideControl {
    border: none;
    outline: none;
    background-color: inherit;
  }
}
