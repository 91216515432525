.noContentSectionMain {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  padding: 60px 0;

  .noDataImage {
    margin: 0 auto;
  }

  .noContentMessage {
    font-size: 15px;
    text-align: center;
    margin-top: 15px;
  }

  .addHereControl {
    margin: 15px auto 0 auto;
  }

  .addHereWithNotApplicable {
    margin: 15px 2px 0 0;
  }

  .notApplicableContainer {
    display: flex;
    align-items: baseline;
    gap: 2px;
  }

  .textSpace {
    margin: 0px 10px 0px 10px;
  }

  .credSectionName {
    font-size: 25px;
    text-align: center;
    margin-top: 15px;
  }
}
